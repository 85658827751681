import React, {useEffect, useState} from "react";
import HeaderVideoWebm from "../video/bg-header.webm";
import HeaderVideoMp4 from "../video/bg-header.mp4";

const NotFound = () => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        document.title = "404"
        window.scrollTo(0, 0);
        setChecked(true);
    }, []);

    return (
        <div className="header min-vh-100 page shadow-mask d-flex align-items-center">
            <video
                width={'100%'}
                className=" video header min-vh-100 d-flex justify-content-center align-items-center"
                controls={false}
                autoPlay
                muted
            >
                <source src={HeaderVideoWebm} type='video/webm'/>
                <source src={HeaderVideoMp4} type="video/mp4"/>
            </video>

            <div style={{zIndex: 10}} className="container position-relative">
                <h1>{404}</h1>
            </div>
        </div>
    );
}

export default NotFound;