import React, {useContext, useMemo, useState} from 'react';
import NftItem from '../components/nftItem';
import nfts from "../nfts.json";
import content from "../content.json";
import {NftServiceContext} from "../utils/nftService";
import Pagination from "@mui/material/Pagination";
import Stack from '@mui/material/Stack';

const NftList = () => {
    const {selectedCategory} = useContext(NftServiceContext);
    const itemsPerPage = 16; // Кількість елементів на сторінці

    // Створюємо стейт для відстеження поточної сторінки
    const [currentPage, setCurrentPage] = useState(1);

    // Фільтруємо NFT за вибраним розділом
    const filteredNfts = useMemo(() => {
        const allCategory = content.home.categories[0];

        return nfts.filter((item) => {
            setCurrentPage(1);
            if (selectedCategory === allCategory) {
                return true;
            }

            return item.attributes[4].value === selectedCategory;
        })
    }, [selectedCategory]);

    // Обчислюємо загальну кількість сторінок на основі кількості NFT і кількості на сторінці
    const pageCount = Math.ceil(filteredNfts.length / itemsPerPage);

    // Функція для отримання поточних NFT на поточній сторінці
    const getCurrentNfts = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredNfts.slice(startIndex, endIndex);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);

        const discoverElement = document.getElementById('discover');
        if (discoverElement) {
            const topOffset = discoverElement.getBoundingClientRect().top;
            const offset = window.pageYOffset + topOffset;
            window.scrollTo({
                top: offset,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div>
            <div className={"row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center"}>
                {getCurrentNfts().map((item, index) => (
                    <NftItem key={index} item={item} index={index + 1}/>
                ))}
            </div>

            <Stack spacing={2} direction="row" justifyContent="center" mt={3}>
                <Pagination
                    className='pagination'
                    size="large"
                    page={currentPage}
                    count={pageCount}
                    onChange={(_, value) => handlePageChange(value)}
                />
            </Stack>

        </div>
    );
};

export default NftList;
