import React, {forwardRef, useContext} from "react";
import {Outlet} from "react-router-dom";
import NavbarComponent from "./navbar";
import FooterComponent from "./footer";
import {Snackbar} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {NftServiceContext} from "../utils/nftService";
import DialogComponent from "../components/modalBuy";

const Alert = forwardRef(function Alert({variant, ...rest}, ref) {
    return <MuiAlert elevation={6} ref={ref} variant={variant || "filled"} {...rest} />;
});

const Layout = () => {
    const context = useContext(NftServiceContext);
    const alert = context?.alert || {type: "", message: ""};
    const setAlert = context?.setAlert || {};

    const handleCloseAlert = () => {
        setAlert({type: "", message: ""});
    };

    return (<>
        <NavbarComponent/>
        <Outlet/>

       <div className="container">
           <Snackbar open={alert.type === "error"} autoHideDuration={5000} onClose={handleCloseAlert}>
               <Alert onClose={handleCloseAlert} severity="error" >
                   {alert.message}
               </Alert>
           </Snackbar>

           <Snackbar open={alert.type === "success"} autoHideDuration={5000} onClose={handleCloseAlert}>
               <Alert onClose={handleCloseAlert} severity="success" >
                   {alert.message}
               </Alert>
           </Snackbar>
       </div>

        <DialogComponent/>
        <FooterComponent/>
    </>)
}

export default Layout;