import React, {createContext, useState, useMemo} from "react";
import {Web3Provider} from "@ethersproject/providers";
import content from "../content.json";

export const NftServiceContext = createContext();

const NftService = ({children}) => {
    const [walletAddress, setWalletAddress] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(content.home.categories[0])

    const [alert, setAlert] = useState({type: "", message: ""});

    const provider = useMemo(() => {
        if (window.ethereum) {
            return new Web3Provider(window.ethereum);
        }
        return {};
    }, []);

    const [selectedNft, setSelectedNft] = useState({})
    const [showDialog, setShowDialog] = useState(false)

    const value = useMemo(() => ({
        walletAddress,
        setWalletAddress,
        selectedCategory,
        setSelectedCategory,
        alert,
        setAlert,
        provider,
        selectedNft,
        setSelectedNft,
        showDialog,
        setShowDialog
    }), [
        walletAddress,
        setWalletAddress,
        selectedCategory,
        setSelectedCategory,
        alert,
        setAlert,
        provider,
        selectedNft,
        setSelectedNft,
        showDialog,
        setShowDialog
    ])

    return <NftServiceContext.Provider value={value}>{children}</NftServiceContext.Provider>;
}

export default NftService;

