import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {ReactComponent as ReactSearchIcon} from '../images/search.svg'
import content from '../content.json'
import MetaTags from "react-meta-tags";

const Blog = () => {
    const [items, setItems] = useState(content.blog.items);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [search, setSearch] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const filterItem = (item) => {
        const newItem = content.blog.items.filter((newVal) => {
            return newVal.category === item;
        });
        setItems(newItem);
        setSelectedCategory(item);
    };

    const filterItemByTitle = (e) => {
        const results = content.blog.items.filter((item) => {
            if (e.target.value.length === 0) {
                return item;
            }
            return (
                item.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
                item.content.toLowerCase().includes(e.target.value.toLowerCase())
            );
        });

        setSelectedCategory('All');
        setSearch(e.target.value);
        setItems(results);
    };

    const categories = [...new Set(content.blog.items.map((item) => item.category))];

    return (
        <>
            <MetaTags>
                <title>Blog - Mintiero</title>
            </MetaTags>

            <div className="header page blog page shadow-mask blog">
                <div className="blog container position-relative">
                    <div className="row">
                        <div className="col-12 col-md-9 m-auto" style={{zIndex: 10}}>
                            <h1 className="mt-5">{content.blog.title}</h1>

                            <div className="row">
                                <div className="col-12 col-lg-8">
                                    {items.length > 0 &&
                                        items.map((item, key) => (
                                                <Link
                                                    to={`/blog/${key}`}
                                                    key={key}
                                                >
                                                    <div
                                                        className="item animate__animated animate__fadeInUp animate__faster mb-5">
                                                        <div className="content">
                                                            <div className="d-block mb-3">
                                                                <span
                                                                    className={`btn btn-category ${item.category}`}
                                                                >
                                                                    {item.category}
                                                                </span>
                                                                <span className="time">{item.date}</span>
                                                            </div>

                                                            <h3 className="mb-3">{item.title}</h3>

                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        item.content.slice(0, 325) + '...',
                                                                }}
                                                            />

                                                        </div>

                                                        <img
                                                            alt={item.title}
                                                            src={item.image}
                                                        />
                                                    </div>
                                                </Link>
                                            ))}
                                    {items.length === 0 && (
                                        <div>
                                            <div className="item">
                                                <div className="content">
                                                    <h4 className="mb-0">{content.blog.not_found}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="col-12 col-lg-4 animate__animated animate__fadeInRight animate__faster filter">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control bg-transparent border-end-0"
                                            placeholder="Search"
                                            onChange={filterItemByTitle}
                                            value={search}
                                        />
                                        <span className="input-group-text bg-transparent border-start-0">
                                                <button className="btn input-group-text text-white">
                                                    <ReactSearchIcon/>
                                                </button>
                                            </span>
                                        </div>

                                        <ul className="f-categories mt-4">
                                            <li
                                                onClick={() => {
                                                    setItems(content.blog.items);
                                                    setSelectedCategory('All');
                                                }}
                                                className={
                                                    'All' === selectedCategory ? 'active' : 'All'
                                                }
                                            >
                                                All
                                            </li>
                                            {categories.map((item, id) => {
                                                return (
                                                    <li
                                                        className={
                                                            item === selectedCategory ? 'active' : item
                                                        }
                                                        key={id}
                                                        onClick={() => filterItem(item)}
                                                    >
                                                        {item}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
};

export default Blog;