import React, {memo, useContext} from 'react';
import content from "../content.json";
import {Link} from "react-router-dom";
import {NftServiceContext} from "../utils/nftService";
import 'react-lazy-load-image-component/src/effects/blur.css';

const NftItemComponent = memo(({item, index}) => {
    const {name, price} = item || {};
    const currency = process.env.REACT_APP_CURRENCY;

    const {setShowDialog, setSelectedNft} = useContext(NftServiceContext);

    const handleClickOpen = () => {
        setShowDialog(true)
        setSelectedNft(item)
    }

    return (
        <div className='col mb-4'>
            <div className='product'>
                <Link to={`/market/${item?.id}`}>
                    <div className='image justify-content-center align-items-center d-flex'>
                        <img
                            alt={name}
                            src={`/nfts/${item?.image_web}`}
                        />
                    </div>
                    <div className='content'>
                        <p className='name'>{name}</p>
                        <p className='price'>
                            {price} {currency}
                        </p>
                    </div>
                </Link>
                <button
                    onClick={handleClickOpen}
                    className='btn w-100'
                    disabled={!name}
                >
                    {content.home.btn_buy}
                </button>
            </div>
        </div>
    );
});

export default NftItemComponent;