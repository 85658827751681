import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as ReactTwitterIcon} from "../images/social/twitter.svg";
import content from '../content.json'
import ButtonConnectComponent from "../components/buttonConnect";

const NavbarComponent = () => {
    const [navbar, setNavbar] = useState(false);
    const [navbarChecked, setNavbarChecked] = useState(false);

    useEffect(() => {
        changeBackground();
        window.addEventListener("scroll", changeBackground);
    }, [])

    const changeBackground = () => {
        if (window.scrollY >= 66) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    const hideMenu = () => {
        setNavbarChecked(false);
        document.body.classList.remove("scroll-off");
    }

    const setScroll = () => {
        setNavbarChecked(!navbarChecked);

        if (navbarChecked) {
            document.body.classList.remove("scroll-off");
            setNavbarChecked(false);
        } else {
            document.body.classList.add("scroll-off");
            setNavbarChecked(true);
        }
    }

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({behavior: 'smooth'});
        }
    };

    const handleMenuClick = (sectionId) => {
        scrollToSection(sectionId);
        hideMenu();
    };

    return (
        <div>
            <nav className={navbar ? "navbar header-scroll" : "navbar"}>
                <div className="navbar-container container">
                    <div className="logo animate__animated animate__fadeInLeft animate__faster">
                        <Link to="/#home" onClick={() => handleMenuClick('home')}>
                            <img src="/images/logo.png" alt=""/>
                        </Link>
                    </div>
                    <input type="checkbox" id="check" checked={navbarChecked} onChange={() => setScroll()}/>
                    <div className="hamburger-lines">
                        <span className="line line1"></span>
                        <span className="line line2"></span>
                    </div>
                    <ul className="menu-items">
                        <li>
                            <Link to="/#home" onClick={() => handleMenuClick('home')}>{content.header.menu.home}</Link>
                        </li>
                        <li>
                            <Link to="/#discover"
                                  onClick={() => handleMenuClick('discover')}>{content.header.menu.discover}</Link>
                        </li>
                        <li>
                            <Link to="/faq" onClick={() => {
                                hideMenu()
                                window.scrollTo(0, 0)
                            }}>
                                {content.header.menu.faq}
                            </Link>
                        </li>
                        <li>
                            <Link to="/blog" onClick={() => {
                                hideMenu()
                                window.scrollTo(0, 0)
                            }}>
                                {content.header.menu.blog}
                            </Link>
                        </li>
                        <li>
                            <Link to="/about" onClick={() => {
                                hideMenu()
                            }}>
                                {content.header.menu.about}
                            </Link>
                        </li>
                        <li>
                            <Link to="#contact_us"
                                  onClick={() => handleMenuClick("contact_us")}>{content.header.menu.contact}</Link>
                        </li>
                        <li>
                            <a className="btn btn-twitter" href={content.social.twitter} target="_blank"
                               rel="noreferrer">
                                <ReactTwitterIcon/>
                            </a>
                        </li>
                        <li>
                            <ButtonConnectComponent/>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default NavbarComponent;