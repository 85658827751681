import React, {useEffect, useState} from 'react';
import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HeaderVideoWebm from '../video/bg-header.webm';
import HeaderVideoMp4 from '../video/bg-header.mp4';
import content from '../content.json';
import 'animate.css/animate.min.css'
import MetaTags from "react-meta-tags";

const FAQ = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [selectedValue, setSelectedValue] = useState({});
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setChecked(true);
    }, []);

    const handleClickOpen = (item) => {
        setShowDialog(true);
        setSelectedValue(item);
    };

    const handleClose = (value) => {
        setShowDialog(false);
        setSelectedValue(value);
    };

    return (
        <>
            <MetaTags>
                <title>FAQ - Mintiero</title>
            </MetaTags>

            <div className="header min-vh-100 page shadow-mask">
                <video
                    width="100%"
                    className="video header min-vh-100 d-flex justify-content-center align-items-center"
                    autoPlay
                    muted
                >
                    <source src={HeaderVideoWebm} type="video/webm"/>
                    <source src={HeaderVideoMp4} type="video/mp4"/>
                </video>

                    <div style={{zIndex: 10}} className="container position-relative animate__animated animate__fadeInUp animate__faster">
                        <h1 className="mt-5">{content.faq.title}</h1>
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-center">
                            {content.faq.items.map((item, key) => (
                                <div
                                    className="col mb-4"
                                    onClick={() => handleClickOpen(item)}
                                    key={key}
                                >
                                    <div
                                        className="faq-item text-white h-100 d-flex align-items-center justify-content-center">
                                        <h5>{item.title}</h5>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <Dialog
                            keepMounted
                            onClose={() => handleClose({})}
                            open={showDialog}
                            aria-describedby="alert-dialog-description"
                            PaperProps={{
                                style: {border: '1px solid #fff', borderRadius: 6},
                            }}
                        >
                            <DialogTitle className="dialog-title">
                                <div className="col-11">{selectedValue.title}</div>
                                <div className="col-1">
                                    <CloseIcon className="close-icon" onClick={() => handleClose({})}/>
                                </div>
                            </DialogTitle>
                            <DialogContent className="dialog-content dialog-content-text">
                                {selectedValue.content}
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
        </>
    );
};

export default FAQ;
