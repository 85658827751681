import React, { forwardRef, useContext } from 'react';
import { Dialog, DialogContent, DialogTitle, Fade } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import content from '../content.json';
import NFTPayButton from '../components/NFTPayButton';
import { NftServiceContext } from '../utils/nftService';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Transition = forwardRef(function Transition(props, ref) {
    return <Fade direction="bottom" ref={ref} {...props} />;
});
function DialogComponent() {
    const {
        selectedNft,
        setSelectedNft,
        showDialog,
        setShowDialog
    } = useContext(NftServiceContext);

    const handleClose = () => {
        setShowDialog(false);
        setSelectedNft({});
    };

    const getPaperUuid = () => {
        switch (selectedNft?.attributes?.[4]?.value) {
            case 'Minimalism':
                return 'bd7e0993-bd98-4a58-b216-864d1798a83c';
            case 'Realism':
                return '4e43782a-6fc8-4350-a0c1-5c8b1e618c7d';
            case 'Doodle':
                return '6211201c-3758-4162-9901-7258ebfbe864';
            case 'Impressionism':
                return '1149a5f5-c82f-4c74-a506-6c23ad1d97a6';
            default:
                return '';
        }
    };

    const { image_web, name } = selectedNft || {};

    return (
        <Dialog
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={showDialog}
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { border: '1px solid #fff', borderRadius: 6, maxWidth: '550px' } }}
        >
            <DialogTitle className="dialog-title">
                <CloseIcon className="close-icon" onClick={handleClose} />
            </DialogTitle>
            <DialogContent className="dialog-content dialog-content-text">
                <div className="row">
                    <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                        <div className="dialog-content-image">
                            <img alt={name} src={`/nfts/${image_web}`} />
                        </div>
                    </div>

                    <div className="col-12 col-lg-8">
                        <p className="mb-3">
                            {content.dialog.title} {name}
                        </p>

                        <NFTPayButton contractUuid={getPaperUuid()} tokenId={selectedNft.id} />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
export default DialogComponent;
