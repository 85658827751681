import React, {useEffect} from "react";
import content from '../content.json'

const PrivacyPolicyPage = () => {
    useEffect(() => {
        document.title = content.privacyPolicy.title
        window.scrollTo(0, 0);
    }, []);

    return (<div>
        <div className="page">
            <div className="container mb-5">
                <h1>{content.privacyPolicy.title}</h1>
                <p>
                    {content.privacyPolicy.description}
                </p>
            </div>
        </div>
    </div>);
}

export default PrivacyPolicyPage;