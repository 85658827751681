import React, {useContext, useEffect, useState} from 'react'
import HeaderVideoWebm from '../video/bg-header.webm'
import HeaderVideoMp4 from '../video/bg-header.mp4'
import content from '../content.json'
import NftList from '../components/nftList';
import {NftServiceContext} from "../utils/nftService";
import 'animate.css/animate.min.css'
import MetaTags from 'react-meta-tags'

const HomePage = () => {
    const {selectedCategory, setSelectedCategory} = useContext(NftServiceContext);

    useEffect(() => {
        document.title = "Mintiero"
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <MetaTags>
                <title>Mintiero - Collect the Ultimate NFT Collection of Futuristic Warriors</title>
                <meta name='description'
                      content='The Cyber Samurai NFT Collection: unique digital collectibles featuring futuristic cybernetic warriors. One-of-a-kind pieces of digital art, perfect for fans of sci-fi, cyberpunk, and futuristic themes.' />
                <meta name='keywords' content='Mintiero, NFT, The Cyber Samurai, digital collectibles, cybernetic warriors, sci-fi, cyberpunk, futuristic themes' />
            </MetaTags>
            <div
                className='animate__animated animate__flash animate__faster header d-flex justify-content-center align-items-center'
                id='home'>
                <video
                    width={'100%'}
                    className='video header min-vh-100 d-flex justify-content-center align-items-center'
                    controls={false}
                    autoPlay
                    muted
                >
                    <source src={HeaderVideoWebm} type='video/webm'/>
                    <source src={HeaderVideoMp4} type='video/mp4'/>
                </video>

                <div className='container position-relative'>
                    <h1 className='mt-lg-5'>{content.home.title}</h1>
                </div>

                <ul className='categories animate__animated animate__fadeInUp animate__faster'>
                    {content.home.categories.map((item, id) => {
                        return (
                            <li
                                className={item === selectedCategory ? 'active' : ''}
                                key={id}
                                onClick={() => {
                                    document.getElementById('discover').scrollIntoView()
                                    setSelectedCategory(item)
                                }}
                            >
                                {item}
                            </li>)
                    })}
                </ul>
            </div>

            <div className='shop' id='discover'>
                <div className='container'>
                    <NftList/>

                    {/*<div className='text-center mt-3'>*/}
                    {/*    <button*/}
                    {/*        className='btn-scroll'*/}
                    {/*        onClick={() => {*/}
                    {/*            window.scrollTo(0, 0)*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <span>{content.home.btn_scroll}</span>*/}
                    {/*        <span>*/}
                    {/*                <ReactArrowIcon/>*/}
                    {/*            </span>*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    )
}

export default HomePage