import React, {useEffect, useState} from "react";
import HeaderVideoWebm from "../video/bg-header.webm";
import HeaderVideoMp4 from "../video/bg-header.mp4";
import {NavLink} from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import MetaTags from "react-meta-tags";

const AboutPage = () => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setChecked(true);
    }, []);

    return (
        <div>
            <MetaTags>
                <title>About Mintiero</title>
                <meta name='description'
                      content='Discover Unique NFTs at GURTIDEX LTD. Our mission is to make NFTs accessible to all, collaborating with global artists to create high-quality collectibles and investment assets. Explore a diverse range of NFTs from digital art to exclusive event access. Join our thriving NFT community and experience safe, reliable acquisition methods. Start your NFT journey with us today!' />
            </MetaTags>

            <div className="page blog">
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-11 col-lg-9 m-auto">
                            <div className="open-post animate__animated animate__fadeInUp animate__faster">
                                <div className="text-center mt-5">
                                    <NavLink to="/" className="mb-4 d-block ">
                                        <ReplyIcon className="btn-back"/>
                                    </NavLink>

                                    <h1 className="mb-4">About us</h1>
                                </div>
                                <p><strong>GURTIDEX LTD.</strong> is a company specialized in the development and production of NFTs (Non-Fungible Tokens). Led by our Director, <strong>Sahil Timblo</strong>, we collaborate with talented artists and designers from around the world to create unique and high-quality NFTs that serve as collectible and investment assets.</p>
                                <p><strong>Our mission</strong> is to make NFTs an accessible and widespread product. We aim for everyone to have the opportunity to acquire NFTs and become part of this exciting new industry.</p>
                                <p><strong>We offer </strong>a wide range of NFTs, from digital art to musical works, from video games to exclusive access to events. We are constantly working on new and innovative projects, confident that our selection of NFTs will satisfy even the most discerning collector.</p>
                                <p><strong>Our Advantages:</strong></p>
                                <ol>
                                    <li>We collaborate with talented artists and designers from around the world.</li>
                                    <li>We offer a diverse range of NFTs, including digital art, music, video games, and exclusive event access.</li>
                                    <li>We are constantly working on new and innovative projects.</li>
                                    <li>We provide safe and reliable methods for acquiring NFTs.</li>
                                    <li>We are committed to delivering high-quality customer service.</li>
                                </ol>
                                <p><strong>We hope you'll become part of our NFT community!</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;