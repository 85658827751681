import React, {useEffect, useState} from "react";
import ReplyIcon from '@mui/icons-material/Reply';
import {Navigate, NavLink} from "react-router-dom";
import content from '../content.json'
import MetaTags from "react-meta-tags";

const PostPage = () => {
    const [post, setPost] = useState({});
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        const data = content?.blog?.items[window.location.pathname.split('/')[2]];

        if (data) {
            setPost(data);
        } else {
            setRedirect("/404");
        }
    }, []);

    if (redirect) {
        return <Navigate to={redirect}/>
    }

    return (
        <div>
            <MetaTags>
                <title>{content?.blog?.items[window.location.pathname.split('/')[2]]?.title} - Mintiero</title>
            </MetaTags>

            <div className="page blog">
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-11 col-lg-9 m-auto">
                            <div className="open-post animate__animated animate__fadeInUp animate__faster">
                                <div className="text-center mt-5">
                                    <NavLink to="/blog" className="mb-4 d-block ">
                                        <ReplyIcon className="btn-back"/>
                                    </NavLink>

                                    <h1 className="mb-4">{post?.title}</h1>
                                </div>
                                <p>
                                    <div dangerouslySetInnerHTML={{__html: post?.content}}/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </div>
    );
}

export default PostPage;