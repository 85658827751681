import React, {useContext} from 'react';
import {ReactComponent as ReactMetamask} from "../images/metamask.svg";
import {NftServiceContext} from "../utils/nftService";
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';

const ButtonConnectComponent = ({itemClass}) => {
    const {walletAddress, setWalletAddress, setAlert} = useContext(NftServiceContext);

    async function switchToPolygon() {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: process.env.REACT_APP_NETWORK_ID}],
            });
        } catch (error) {
            try {
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            chainId: process.env.REACT_APP_NETWORK_ID,
                            chainName: "Polygon Network",
                            nativeCurrency: {
                                name: "Matic",
                                symbol: "MATIC",
                                decimals: 18,
                            },
                            rpcUrls: ["https://polygon.llamarpc.com"],
                            blockExplorerUrls: ["https://polygonscan.com"],
                        },
                    ],
                });
            } catch (_error) {
                await setAlert({type: "error", message: _error.message});
            }
            await setAlert({type: "error", message: error.message});
        }
    }

    const connectWallet = async () => {
        if (window.ethereum?.isMetaMask) {
            try {
                try {
                    await window.ethereum.request({method: 'eth_requestAccounts'});
                } catch (accountsError) {
                    setAlert({type: "error", message: "Failed to connect to Metamask. Please try again."});
                }
                const accounts = await window.ethereum.request({method: 'eth_accounts'});
                const chainId = await window.ethereum.request({method: 'eth_chainId'});
                if (chainId !== process.env.REACT_APP_NETWORK_ID) {
                    switchToPolygon();
                } else {
                    let wallet = accounts[0];
                    setWalletAddress(wallet);
                    setAlert({type: "success", message: "Welcome! You have successfully signed in."});
                }
            } catch (error) {
                setAlert({type: "error", message: "Failed to connect to Metamask. Please try again."});
            }
        } else {
            setAlert({type: "error", message: "Please install Metamask"});
        }
    };

    return (
        <>
            {walletAddress ? (
                <a target="_blank" rel="noreferrer" href={`https://polygonscan.com/address/${walletAddress}`}
                   className="btn-wallet active">
                    <ReactMetamask/>
                    <span> {walletAddress.slice(0, 4) + "..." + walletAddress.slice(walletAddress.length - 4, walletAddress.length)}</span>
                </a>
            ) : (
                <button className={`btn-wallet  ${itemClass ?? ''}`} onClick={connectWallet}>
                    <AccountBalanceWalletRoundedIcon/>
                    <span>Connect Metamask</span>
                </button>
            )}
        </>
    );
}

export default ButtonConnectComponent;
