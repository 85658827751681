import React from 'react';
import {NavLink} from "react-router-dom";
import {ReactComponent as ReactFooterTwitterIcon} from "../images/social/f-twitter.svg";
import {ReactComponent as ReactFooterInstagramIcon} from "../images/social/instagram.svg";
import content from "../content.json";
const FooterComponent = () => {
    return (
        <footer id="contact_us">
            <div className="container">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                    <div className="col mb-4 mb-lg-0">
                        <div className="info">
                            <a href="/" className="footer-logo">
                                <img src="/images/logo.png" alt=""/>
                            </a>
                            <p>Fuel the rise of the Digital Content World</p>
                        </div>
                    </div>

                    <div className="col mb-4 mb-lg-0">
                        <h4>{content.footer.about.title}</h4>

                        <ul className="link">
                            <li>
                                <NavLink to="blog">
                                    {content.footer.about.menu.blog}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="faq">
                                    {content.footer.about.menu.faq}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="privacy-policy">
                                    {content.footer.about.menu.privacy_policy}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="terms-of-use">
                                    {content.footer.about.menu.terms_of_service}
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="col mb-4 mb-lg-0">
                        <h4>{content.footer.contact.title}</h4>

                        <div className="contact">
                            <a href={`mailto:${content.footer.contact.email}`}>{content.footer.contact.email}</a>
                        </div>
                    </div>

                    <div className="col">
                        <h4>{content.footer.social.title}</h4>

                        <ul className="soc">
                            <li>
                                <a href={content.social.twitter} target="_blank" rel="noreferrer">
                                    <ReactFooterTwitterIcon/>
                                </a>
                            </li>
                            <li>
                                <a href={content.social.instagram} target="_blank" rel="noreferrer">
                                    <ReactFooterInstagramIcon/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;