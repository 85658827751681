import React, {useState, useEffect, useContext} from 'react'
import {CircularProgress, Box} from '@mui/material'
import {ReactComponent as ReactPolygonIcon} from '../images/polygon.svg'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import content from '../content.json'
import {NftServiceContext} from "../utils/nftService";
import nfts from "../nfts.json";
import {Navigate} from "react-router-dom";
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'animate.css/animate.min.css'
import MetaTags from "react-meta-tags";

const NftPage = () => {
    const [nft, setNft] = useState(false)
    const [redirect, setRedirect] = useState(null);

    const {setShowDialog, setSelectedNft} = useContext(NftServiceContext);

    useEffect(() => {
        window.scrollTo(0, 0)
        const getNft = async () => {
            const id = window.location.pathname.split('/')[2];

            const foundNft = nfts.find(nft => nft.id == id);

            if (foundNft) {
                setNft(foundNft);
            } else {
                setRedirect("/404");
            }
        }

        getNft()
    }, []);

    const currency = process.env.REACT_APP_CURRENCY;

    if (redirect) {
        return <Navigate to={redirect}/>
    }

    return (<div>
        <MetaTags>
            <title>{nft?.name} - Collect the Ultimate NFT Collection of Futuristic Warriors</title>
        </MetaTags>

        <div className='page blog'>
            <div className='container mt-5'>
                <div className='nft'>
                    <div className='row'>
                        <div className='col-12 col-lg-5 order-1 order-lg-0'>
                            <div className='image mb-4 animate__animated animate__fadeInDown animate__faster'>
                                {nft?.image_web ? (
                                    <img
                                        alt={nft?.name}
                                        src={`/nfts/${nft.image_web}`}
                                    />
                                ) : (
                                    <Box sx={{display: 'flex'}} className='justify-content-center'>
                                        <CircularProgress size='10rem' style={{'color': '#D32E2E'}}/>
                                    </Box>
                                )}
                            </div>

                            <div className='row row-cols-2 row-cols-xl-3 animate__animated animate__fadeInUp animate__faster'>
                                {nft?.attributes && nft?.attributes.map((item, key) => (
                                    <div className='col mb-3' key={key}>
                                        <div className='attribute h-100'>
                                            <p className='name mb-1'>{item.trait_type}</p>
                                            <p className='value mb-0'>{item.value}</p>
                                        </div>
                                    </div>))}
                            </div>
                        </div>

                        <div className='col-12 col-lg-7 ps-lg-4'>
                            <div className='content animate__animated animate__fadeInRight animate__faster'>
                                <h1 className='name mb-2'>{nft?.name}</h1>
                                <div>
                                    <p className='price'>
                                        {nft?.price} {currency}
                                    </p>

                                    <button className='btn-buy'
                                            onClick={(e) => {
                                                setShowDialog(true)
                                                setSelectedNft(nft)
                                            }}>
                                        {content.nft.btn_buy}</button>
                                </div>

                                <hr/>
                                <div className='mb-4'>
                                    <h4>{content.nft.tab_description}</h4>
                                    <p>{nft?.description}</p>
                                </div>
                                <hr/>
                                <div className='mb-4'>
                                    <h4 className='mb-3'>{content.nft.tab_chain_info}</h4>

                                    <div className='network mb-3 me-3'>
                                        <ReactPolygonIcon/>
                                        <span>{content.nft.network}</span>
                                    </div>

                                    {/*<a href={nft?.rawMetadata?.image} target='_blank' rel='noreferrer'*/}
                                    {/*   className='btn-view mb-3'>{content.nft.view}</a>*/}

                                    <ul className='tabs mb-4 mt-2'>
                                        <li>
                                            <b>{content.nft.type}:</b> ERC1155
                                        </li>
                                        <li>
                                            <b>{content.nft.contract_address}:</b>
                                            {process.env.REACT_APP_CONTRACT.slice(0, 8)}...{process.env.REACT_APP_CONTRACT.slice(process.env.REACT_APP_CONTRACT.length - 4, process.env.REACT_APP_CONTRACT.length)}
                                            <ContentCopyIcon className="copy ms-2" onClick={() => {
                                                navigator.clipboard.writeText(process.env.REACT_APP_CONTRACT)
                                            }}/>
                                        </li>
                                        <li>
                                            <b>{content.nft.token}:</b> {nft.id}
                                            <ContentCopyIcon className="copy ms-2" onClick={() => {
                                                navigator.clipboard.writeText(nft.id)
                                            }}/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default NftPage