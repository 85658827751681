import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Layout from "./layouts/layout";
import HomePage from "./pages/home.page";
import FaqPage from "./pages/faq.page";
import "./App.scss";
import BlogPage from "./pages/blog.page";
import TermsOfUsePage from "./pages/terms-of-use.page";
import PrivacyPolicyPage from "./pages/privacy-policy";
import PostPage from "./pages/post.page";
import NftPage from "./pages/nft.page";
import NotFound from "./pages/not-found.page";
import AboutPage from "./pages/about.page";
function App() {
    return (<div className="App">
        <Router>
            <Routes>
                <Route element={<Layout />}>
                    <Route
                        exact
                        path="/"
                        element={<HomePage/>}
                    />
                    <Route
                        exact
                        path="/market/:id"
                        element={<NftPage/>}
                    />
                    <Route exact path="/faq" element={<FaqPage/>}/>
                    <Route exact path="/about" element={<AboutPage/>}/>
                    <Route exact path="/blog" element={<BlogPage/>}/>
                    <Route exact path="/blog/:id" element={<PostPage/>}/>
                    <Route exact path="/terms-of-use" element={<TermsOfUsePage/>}/>
                    <Route exact path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
                    <Route path='*' element={<NotFound/>}/>
                </Route>
            </Routes>
        </Router>
    </div>);
}

export default App;