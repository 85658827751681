import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import NftService from "./utils/nftService";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <NftService>
            <App/>
        </NftService>
    </React.StrictMode>
);

reportWebVitals();
