import React, { useEffect } from 'react';
import content from "../content.json";
import { ReactComponent as ReactNftpayIcon } from '../images/nftpay-logo-icon.svg';

function NFTPayButton({ contractUuid, tokenId }) {
    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'creatify_script';
        script.src = `https://payments.nftpay.xyz/libs/iframe_inject.js?contract_uuid=${contractUuid}&_tokenId=${tokenId}`;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [contractUuid, tokenId]);

    return (
        <>
            <button onClick={() => window.show_creatify_popup()}
                    className="btn-mint mb-2"
            >
                <ReactNftpayIcon />
                {content.dialog.buy_with_nftpay}
            </button>
        </>
    );
}

export default NFTPayButton;
